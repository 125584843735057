import React from "react";
import { PDFDocument, rgb } from "pdf-lib";

const PdfGenerator = () => {
  const handleGeneratePdf = async () => {
    // Create a new PDF document
    const pdfDoc = await PDFDocument.create();

    // Add a new page to the PDF
    const newPage = pdfDoc.addPage([612, 792]); // Standard letter size: 8.5 x 11 inches

    // Embed the Helvetica font
    const font = await pdfDoc.embedFont(PDFDocument.Font.Helvetica);

    // Add text to the new page
    const textSize = 12;
    const text = "Hello, this is added content!";
    newPage.drawText(text, {
      x: 50,
      y: 742,
      font,
      fontSize: textSize,
      color: rgb(0, 0, 0),
    });

    // Save the created PDF
    const createdPdfBytes = await pdfDoc.save();

    // You can use createdPdfBytes as needed (e.g., send to server, display, or download)

    // For this example, let's log the bytes to the console
    // console.log("Created PDF:", createdPdfBytes);
  };

  return (
    <div style={{ marginTop: "100px" }}>
      <h1>PDF Generator</h1>
      <button onClick={handleGeneratePdf}>Generate PDF</button>
    </div>
  );
};

export default PdfGenerator;
