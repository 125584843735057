import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import BreadCrumbsUI from "./BreadCrumbs";

export default function ShippingPolicyUI() {
  const m1 = useMediaQuery("(min-width:500px)");

  return (
    <div style={{ marginTop: "150px" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ width: "90%", marginBottom: "100px" }}>
          <h1
            style={{
              fontSize: m1 ? "30px" : "25px",
              fontWeight: 600,
              color: "white",
            }}
          >
            <BreadCrumbsUI /> Shipping Policy
          </h1>
          <p
            style={{
              fontSize: m1 ? "18px" : "16px",
              color: "white",
              marginTop: "20px",
            }}
          >
            <b> Last Updated: 13th September, 2023</b>
            <br />
            Figry, referred to as "we" and "us", operates the website (figry.in)
            referred to as the "Website". When you place an order through this
            Website, you accept the following terms. These terms are established
            to ensure mutual understanding and agreement between both parties
            regarding our services and to protect the interests of both parties.
          </p>

          <h1
            style={{
              fontSize: m1 ? "23px" : "18px",
              fontWeight: 600,
              color: "white",
              marginTop: "20px",
            }}
          >
            General
          </h1>
          <p
            style={{
              fontSize: m1 ? "18px" : "16px",
              color: "white",
              marginTop: "10px",
            }}
          >
            Subject to stock availability, we make an effort to keep accurate
            stock numbers on our website. However, on occasion, there may be an
            error in our stock count, leading to a situation where we cannot
            fulfil all your items at the time of purchase. If this occurs, we
            will deliver the available products to you and get in touch with you
            to determine if you prefer to wait for the restocked item or receive
            a refund.
          </p>

          <h1
            style={{
              fontSize: m1 ? "23px" : "18px",
              fontWeight: 600,
              color: "white",
              marginTop: "20px",
            }}
          >
            Shipping Costs
          </h1>
          <p
            style={{
              fontSize: m1 ? "18px" : "16px",
              color: "white",
              marginTop: "10px",
            }}
          >
            The shipping fee is calculated during the checkout process, based on
            the weight, dimensions, and delivery location of the items in the
            order. The shipping payment will be added to the overall purchase
            amount and will serve as the final cost for the customer.
          </p>

          <h1
            style={{
              fontSize: m1 ? "23px" : "18px",
              fontWeight: 600,
              color: "white",
              marginTop: "20px",
            }}
          >
            Returns
          </h1>
          <p
            style={{
              fontSize: m1 ? "18px" : "16px",
              color: "white",
              marginTop: "10px",
            }}
          >
            <ol
              style={{
                marginTop: "10px",
                marginLeft: "20px",
                color: "white",
                listStyleType: "disc",
              }}
            >
              <li>
                <b>3.1 Return Due To Change Of Mind</b> <br />
                At Figry, we are more than happy to accommodate returns based on
                a change of mind, provided that the request for return is
                received by us within 2 days of receipt of the item. The item
                must be returned in its original packaging, unopened and in a
                condition that is suitable for resale. The customer will be
                responsible for paying for the return shipping and must arrange
                for the shipping themselves. Once the return is received and
                accepted, we will process a refund in the form of store credit
                for a future purchase. We will notify you via email once the
                process is completed. Please note that while we will refund the
                value of the returned goods, we will not refund the shipping
                costs that were originally paid by the customer.
              </li>
              <li>
                <b>3.2 Warranty Returns</b>
                <br />
                Figry is committed to fulfilling all legitimate warranty claims,
                but they must be submitted within 90 days of receiving the
                items. To facilitate the return process, customers will need to
                pay for the shipping upfront, but Figry will reimburse the cost
                upon a successful warranty claim. Once Figry receives the
                returned items for a warranty claim, they aim to process it
                within 7 days.
                <ul
                  style={{
                    marginTop: "10px",
                    marginLeft: "20px",
                    color: "white",
                    listStyleType: "circle",
                  }}
                >
                  <li>
                    Upon confirming your warranty claim, you will have the
                    option to choose from:Reimbursement to the payment method
                    used
                  </li>
                  <li>An in-store credit refund</li>
                  <li>Receiving a new item (if stock is readily available)</li>
                </ul>
              </li>
            </ol>
          </p>

          <h1
            style={{
              fontSize: m1 ? "23px" : "18px",
              fontWeight: 600,
              color: "white",
              marginTop: "20px",
            }}
          >
            Delivery Terms
          </h1>
          <p
            style={{
              fontSize: m1 ? "18px" : "16px",
              color: "white",
              marginTop: "10px",
            }}
          >
            <ol
              style={{
                marginTop: "10px",
                marginLeft: "20px",
                color: "white",
                listStyleType: "disc",
              }}
            >
              <li>
                <b>4.1 Transit Time Domestically</b> <br />
                Typically, it takes 2 to 7 days for domestic shipments to be
                delivered.
              </li>
              <li>
                <b>4.2 Transit time Internationally</b>
                <br />
                International shipping typically takes between 4 to 22 days in
                transit, with the exact duration depending on the courier
                chosen. An accurate estimate can be provided during the checkout
                process when selecting the courier.
              </li>
              <li>
                <b>4.3 Dispatch time</b>
                <br />
                Orders are typically sent out within 2 working days after
                payment has been received. The warehouse operates from Monday to
                Friday during standard business hours, except on national
                holidays when it is closed. During these holidays, measures are
                taken to minimize shipment delays.
              </li>
              <li>
                <b>4.4 Change Of Delivery Address</b>
                <br />
                Regarding requests for changing the delivery address, we can
                alter it prior to the dispatch of the order.
              </li>
              <li>
                <b>4.5 Items Out Of Stock</b>
                <br />
                In the event of an item being unavailable, we will process a
                cancellation and refund for the out-of-stock item and proceed
                with shipping the remaining items in the order. In case an item
                is not available, we will promptly send the available items and
                will send the rest of them as soon as they are back in stock. In
                the event of an item being unavailable, we will process a
                cancellation and refund for the out-of-stock item and proceed
                with shipping the remaining items in the order.
              </li>
              <li>
                <b>4.6 Delivery Time Exceeded</b>
                <br />
                Please contact us if the delivery time has surpassed the
                expected delivery window, so we can initiate an inquiry.
              </li>
            </ol>
          </p>

          <h1
            style={{
              fontSize: m1 ? "23px" : "18px",
              fontWeight: 600,
              color: "white",
              marginTop: "20px",
            }}
          >
            Tracking Notifications
          </h1>
          <p
            style={{
              fontSize: m1 ? "18px" : "16px",
              color: "white",
              marginTop: "10px",
            }}
          >
            Once their order is dispatched, customers will receive a tracking
            link which will allow them to monitor the status of their shipment,
            as updated by the shipping provider.
          </p>
          <h1
            style={{
              fontSize: m1 ? "23px" : "18px",
              fontWeight: 600,
              color: "white",
              marginTop: "20px",
            }}
          >
            Parcels Damaged In Transit
          </h1>
          <p
            style={{
              fontSize: m1 ? "18px" : "16px",
              color: "white",
              marginTop: "10px",
            }}
          >
            In the event of a damaged parcel during transit, kindly reject it
            from the courier if possible and reach out to our customer service
            team. If the parcel was delivered in your absence, please inform our
            customer service team for further instructions.
          </p>
          <h1
            style={{
              fontSize: m1 ? "23px" : "18px",
              fontWeight: 600,
              color: "white",
              marginTop: "20px",
            }}
          >
            Duties & Taxes
          </h1>
          <p
            style={{
              fontSize: m1 ? "18px" : "16px",
              color: "white",
              marginTop: "10px",
            }}
          >
            <ol
              style={{
                marginTop: "10px",
                marginLeft: "20px",
                color: "white",
                listStyleType: "disc",
              }}
            >
              <li>
                <b>7.1 Sales Tax</b> <br />
                Sales tax has already been applied to the price of the goods as
                displayed on the website.
              </li>
              <li>
                <b> Import Duties & Taxes</b>
              </li>
            </ol>
          </p>
          <h1
            style={{
              fontSize: m1 ? "23px" : "18px",
              fontWeight: 600,
              color: "white",
              marginTop: "20px",
            }}
          >
            Cancellations
          </h1>
          <p
            style={{
              fontSize: m1 ? "18px" : "16px",
              color: "white",
              marginTop: "10px",
            }}
          >
            You may cancel your order at any time prior to dispatch. However, if
            your order has already been dispatched, kindly refer to our refund
            policy for further information.
          </p>
          <h1
            style={{
              fontSize: m1 ? "23px" : "18px",
              fontWeight: 600,
              color: "white",
              marginTop: "20px",
            }}
          >
            Insurance
          </h1>
          <p
            style={{
              fontSize: m1 ? "18px" : "16px",
              color: "white",
              marginTop: "10px",
            }}
          >
            The insurance coverage for the loss and damage of parcels is up to
            the value declared by the courier.
            <ol
              style={{
                marginTop: "10px",
                marginLeft: "20px",
                color: "white",
                listStyleType: "disc",
              }}
            >
              <li>
                <b>9.1 Process for parcel damaged in-transit</b> <br />
                We will process a refund or replacement as soon as the courier
                has completed their investigation into the claim.
              </li>
              <li>
                <b> 9.2 Process for parcel lost in transit</b>As soon as the
                courier finishes investigating the claim, we will proceed with
                either a refund or a replacement.
              </li>
            </ol>
          </p>
          <h1
            style={{
              fontSize: m1 ? "23px" : "18px",
              fontWeight: 600,
              color: "white",
              marginTop: "20px",
            }}
          >
            Customer service
          </h1>
          <p
            style={{
              fontSize: m1 ? "18px" : "16px",
              color: "white",
              marginTop: "10px",
            }}
          >
            For all customer service enquiries, please email us at
            partnerships@figry.in. You can reach us during our working hours by
            calling: +919611442222.
          </p>
        </div>
      </div>
    </div>
  );
}
