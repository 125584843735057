import React from "react";
import "./Styles/PartnerWithUs.css";
import { sendEmailFunc } from "../Utilities/Functions/SendEmail";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import about_us_1 from "./Images/about_us_1.png";
import about_us_2 from "./Images/about_us_2.png";

export default function PartnerWithUs() {
  return (
    <div>
      {/* <ToastContainer /> */}
      <div className="sitemenu_partner_with_us">
        <div className="sitemenu_partner_with_us_inner">
          <div className="sitemenu_partner_with_us_inner_div1">
            <h1 className="sitemenu_partner_with_us_inner_div1_h1">About Us</h1>

            <p className="sitemenu_partner_with_us_inner_div1_p2">
              ﻿Imagine someone filled with excitement, looking forward to
              celebrating a moment in their life or paying tribute to someone.
              In such instances, they turn to FIGRY, the destination for
              creating truly remarkable moments. Whether it's a personal moment,
              an event, a corporate gesture, a dreamy wedding, a collaborative
              partnership with renowned brands, or the expert guidance of
              consultation services - FIGRY is dedicated to ensuring that every
              celebration is infused with its own unique and extraordinary charm
              where the world experiences the joy and impact of a gift, expertly
              chosen and thoughtfully given.
            </p>
            <img
              alt="img"
              src={about_us_1}
              className="sitemenu_partner_with_us_inner_div1_img"
            />
          </div>
          <div className="sitemenu_partner_with_us_inner_div2_about">
            <img
              alt="img"
              src={about_us_2}
              className="sitemenu_partner_with_us_inner_div1_img2"
            />
            <p className="sitemenu_partner_with_us_inner_div1_p22">
              Our vision is to be the premier destination for individuals and
              businesses, celebrating life's precious moments with heartfelt
              gestures, ensuring that the world experiences the joy and impact
              of a gift, expertly chosen and thoughtfully given.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
