import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import BreadCrumbsUI from "./BreadCrumbs";

export default function ReturnAndRefundUI() {
  const m1 = useMediaQuery("(min-width:500px)");

  return (
    <div style={{ marginTop: "150px" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div style={{ width: "90%", marginBottom: "100px" }}>
          <h1
            style={{
              fontSize: m1 ? "30px" : "25px",
              fontWeight: 600,
              color: "white",
            }}
          >
            <BreadCrumbsUI /> Return & Refund Policy
          </h1>
          <p
            style={{
              fontSize: m1 ? "18px" : "16px",
              color: "white",
              marginTop: "20px",
            }}
          >
            <b> Last Updated: 13th September, 2023</b>
            <br />
            Thank you for shopping at Figry. If, for any reason, You are not
            completely satisfied with a purchase We invite You to review our
            policy on refunds and returns. This Return and Refund Policy has
            been created with the help of the Free Return and Refund Policy
            Generator. The following terms are applicable for any products that
            You purchased with Us.
          </p>

          <h1
            style={{
              fontSize: m1 ? "28px" : "23px",
              fontWeight: 600,
              color: "white",
              marginTop: "30px",
            }}
          >
            Interpretation and Definitions
          </h1>
          <h1
            style={{
              fontSize: m1 ? "25px" : "20px",
              fontWeight: 600,
              color: "white",
              marginTop: "20px",
            }}
          >
            Interpretation
          </h1>
          <p
            style={{
              fontSize: m1 ? "18px" : "16px",
              color: "white",
              marginTop: "10px",
            }}
          >
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h1
            style={{
              fontSize: m1 ? "25px" : "20px",
              fontWeight: 600,
              color: "white",
              marginTop: "20px",
            }}
          >
            Definitions
          </h1>
          <p
            style={{
              fontSize: m1 ? "18px" : "16px",
              color: "white",
              marginTop: "10px",
            }}
          >
            For the purposes of this Return and Refund Policy:
            <ul
              style={{
                marginTop: "10px",
                marginLeft: "20px",
                color: "white",
              }}
            >
              <li>
                <b>Company : </b> (referred to as either "the Company", "We",
                "Us" or "Our" in this Agreement) refers to Figry, Bangalore,
                560053.
              </li>
              <li>
                <b>Goods : </b> refer to the items offered for sale on the
                Service.
              </li>
              <li>
                <b> Orders : </b>mean a request by You to purchase Goods from
                Us.
              </li>
              <li>
                <b> Service : </b> refers to the Website.
              </li>
              <li>
                <b> Website : </b>refers to Figry, accessible from
                https://www.figry.in
              </li>
              <li>
                <b> You : </b>means the individual accessing or using the
                Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </li>
            </ul>
          </p>
          <h1
            style={{
              fontSize: m1 ? "28px" : "23px",
              fontWeight: 600,
              color: "white",
              marginTop: "30px",
            }}
          >
            Your Order Cancellation Rights
          </h1>

          <p
            style={{
              fontSize: m1 ? "18px" : "16px",
              color: "white",
              marginTop: "10px",
            }}
          >
            You are entitled to cancel Your Order within 7 days without giving
            any reason for doing so. The deadline for cancelling an Order is 7
            days from the date on which You received the Goods or on which a
            third party you have appointed, who is not the carrier, takes
            possession of the product delivered.
          </p>
          <p
            style={{
              fontSize: m1 ? "18px" : "16px",
              color: "white",
              marginTop: "10px",
            }}
          >
            In order to exercise Your right of cancellation, You must inform Us
            of your decision by means of a clear statement. You can inform us of
            your decision by:
            <ul
              style={{
                marginTop: "10px",
                marginLeft: "20px",
                color: "white",
                listStyleType: "circle",
              }}
            >
              <li>By email: partnerships@figry.in</li>
              <li>By phone number: +91 9611442222</li>
            </ul>
          </p>
          <p
            style={{
              fontSize: m1 ? "18px" : "16px",
              color: "white",
              marginTop: "10px",
            }}
          >
            We will reimburse You no later than 14 days from the day on which We
            receive the returned Goods. We will use the same means of payment as
            You used for the Order, and You will not incur any fees for such
            reimbursement.
          </p>

          <h1
            style={{
              fontSize: m1 ? "28px" : "23px",
              fontWeight: 600,
              color: "white",
              marginTop: "30px",
            }}
          >
            Conditions for Returns
          </h1>

          <p
            style={{
              fontSize: m1 ? "18px" : "16px",
              color: "white",
              marginTop: "10px",
            }}
          >
            In order for the Goods to be eligible for a return, please make sure
            that:
            <ul
              style={{
                marginTop: "10px",
                marginLeft: "20px",
                color: "white",
                listStyleType: "circle",
              }}
            >
              <li>The Goods were purchased in the last 7 days</li>
              <li>The Goods are in the original packaging</li>
            </ul>
          </p>
          <p
            style={{
              fontSize: m1 ? "18px" : "16px",
              color: "white",
              marginTop: "10px",
            }}
          >
            The following Goods cannot be returned:
            <ul
              style={{
                marginTop: "10px",
                marginLeft: "20px",
                color: "white",
                listStyleType: "circle",
              }}
            >
              <li>
                The supply of Goods made to Your specifications or clearly
                personalized.
              </li>
              <li>
                The supply of Goods which according to their nature are not
                suitable to be returned, deteriorate rapidly or where the date
                of expiry is over.
              </li>
              <li>
                The supply of Goods which are not suitable for return due to
                health protection or hygiene reasons and were unsealed after
                delivery.
              </li>
              <li>
                The supply of Goods which are, after delivery, according to
                their nature, inseparably mixed with other items.
              </li>
            </ul>
            <br />
            We reserve the right to refuse returns of any merchandise that does
            not meet the above return conditions in our sole discretion.
          </p>

          <h1
            style={{
              fontSize: m1 ? "28px" : "23px",
              fontWeight: 600,
              color: "white",
              marginTop: "30px",
            }}
          >
            Gifts
          </h1>

          <p
            style={{
              fontSize: m1 ? "18px" : "16px",
              color: "white",
              marginTop: "10px",
            }}
          >
            If the Goods were marked as a gift when purchased and then shipped
            directly to you, You'll receive a gift credit for the value of your
            return. Once the returned product is received, a gift certificate
            will be mailed to You.
          </p>
          <p
            style={{
              fontSize: m1 ? "18px" : "16px",
              color: "white",
              marginTop: "10px",
            }}
          >
            If the Goods weren't marked as a gift when purchased, or the gift
            giver had the Order shipped to themselves to give it to You later,
            We will send the refund to the gift giver.
          </p>
          <h1
            style={{
              fontSize: m1 ? "28px" : "23px",
              fontWeight: 600,
              color: "white",
              marginTop: "30px",
            }}
          >
            Contact Us
          </h1>
          <p
            style={{
              fontSize: m1 ? "18px" : "16px",
              color: "white",
              marginTop: "10px",
            }}
          >
            If you have any questions about our Returns and Refunds Policy,
            please contact us:
            <ul
              style={{
                marginTop: "10px",
                marginLeft: "20px",
                color: "white",
                listStyleType: "circle",
              }}
            >
              <li>By email: partnerships@figry.in</li>
              <li>By phone number: +91 9611442222</li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );
}
