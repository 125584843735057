import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Fade from "@mui/material/Fade";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { lazy, Suspense } from "react";
import Lottie from "react-lottie";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToastContainer } from "react-toastify";
import PrivacyPolicyUI from "./FooterContents/PrivacyPolicy";
import TermsAndConditionsUI from "./FooterContents/TermsAndConditions";
import ShippingPolicyUI from "./FooterContents/ShippingPolicy";
import ReturnPolicyUI from "./FooterContents/ReturnAndRefund";
import { GoogleMapsScript } from "./Utilities/GoogleMapsScript";
import "react-toastify/dist/ReactToastify.css";
import useScrollPosition from "./Modules/Utilities/Hooks/useScrollPosition.js";

import Loader from "./Itineries/Assets/lotties/Loader.json";
import ErrorBoundaryWrapper from "./Itineries/ErrorBoundry/ErrorBoundry";
import AboutUsUI from "./Modules/SiteMenu/AboutUs";
import PartnerWithUsUI from "./Modules/SiteMenu/PartnerWithUs";
import TestUI from "./Test";
import ReactPixel from "react-facebook-pixel";

const HomeUI = lazy(() => import("./Modules/Home/Home"));
const Page1UI = lazy(() => import("./Modules/Products/Categories/Page1.js"));
const Page2UI = lazy(() => import("./Modules/Products/Categories/Page2.js"));
const BlogUI = lazy(() => import("./Modules/SiteMenu/Blog"));
const CarrerUI = lazy(() => import("./Modules/SiteMenu/Careers.js"));
const NewsLetterUI = lazy(() => import("./Modules/SiteMenu/Newletter"));
const ContactUsUI = lazy(() => import("./Modules/SiteMenu/ContactUs"));
const CartUI = lazy(() => import("./Modules/Cart/Cart"));
const CheckoutUI = lazy(() => import("./Modules/Checkout/Checkout"));
const OrdersUI = lazy(() => import("./Modules/Orders/Orders"));
const TrackMyOrderUI = lazy(() => import("./Modules/Orders/TrackMyOrder.js"));
const IndividualProductPageUI = lazy(() =>
  import("./Modules/Products/Product/Product.js")
);
const SideBarUI = lazy(() => import("./Modules/Common/Utilities/SideBar.js"));
const BannerUI = lazy(() => import("./Modules/Banner/Banner.js"));
const ConsultUI = lazy(() => import("./Modules/Common/Consult/Consult.js"));

const Header = lazy(() => import("./Modules/Common/NavBar.js"));
const Footer = lazy(() => import("./components/Footer"));
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000,
    },
  },
});

const LoaderSkeleton = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="grid h-[80vh] grow place-items-center">
      <div>
        <Lottie options={defaultOptions} width={200} />
      </div>
    </div>
  );
};

const App = () => {
  React.useEffect(() => {
    ReactPixel.init(process.env.FACEBOOK_PIXEL_ID);

    // Track a custom event (e.g., button click)
    // ReactPixel.trackCustom("SubmitButtonClicked", {
    //   buttonName: "SubmitButton",
    //   userId: "123456",
    //   timestamp: new Date().toISOString(),
    // });
  }, []);

  return (
    <div className="overflow-hidden">
      <QueryClientProvider client={queryClient}>
        <div className="flex min-h-screen flex-col font-poppins">
          <Suspense fallback={<LoaderSkeleton />}>
            <ErrorBoundaryWrapper>
              <Router>
                {/* <div
                  id={process.env.REACT_APP_GOOGLE_APPLICATION_BUTTON_ID}
                ></div> */}
                <div className="main_background">
                  <h1 className="main_background_h1">
                    Figrying{" "}
                    <span className="main_background_h1_span">
                      {" "}
                      it out for you!
                    </span>
                  </h1>
                </div>
                {/* Header */}
                <BannerUI />
                <SideBarUI />
                <Header />

                <CartUI />
                <Routes>
                  <Route path="/consult" element={<ConsultUI />}></Route>
                  <Route path="/test" element={<TestUI />}></Route>
                  <Route path="/" element={<HomeUI />}></Route>
                  <Route path="/checkout" element={<CheckoutUI />}></Route>
                  <Route path="/orders" element={<OrdersUI />}></Route>
                  <Route
                    path="/track_my_order"
                    element={<TrackMyOrderUI />}
                  ></Route>
                  <Route
                    path="/track_my_order/:id"
                    element={<TrackMyOrderUI />}
                  ></Route>
                  <Route
                    path="/product/:id"
                    element={<IndividualProductPageUI />}
                  ></Route>
                  <Route path="/category/:id" element={<Page1UI />}></Route>
                  <Route path="/category/:id/:id" element={<Page2UI />}></Route>
                  <Route path="/blog" element={<BlogUI />}></Route>
                  <Route path="/newsletter" element={<NewsLetterUI />}></Route>
                  <Route path="/contact_us" element={<ContactUsUI />}></Route>
                  <Route path="/about_us" element={<AboutUsUI />}></Route>
                  <Route path="/careers" element={<CarrerUI />}></Route>
                  <Route
                    path="/partner_with_us"
                    element={<PartnerWithUsUI />}
                  ></Route>
                  <Route
                    path="/privacy_policy"
                    element={<PrivacyPolicyUI />}
                  ></Route>
                  <Route
                    path="/terms_and_conditions"
                    element={<TermsAndConditionsUI />}
                  ></Route>
                  <Route
                    path="/shipping_policy"
                    element={<ShippingPolicyUI />}
                  ></Route>
                  <Route
                    path="/retruns_and_exchanges"
                    element={<ReturnPolicyUI />}
                  ></Route>
                </Routes>
                {/* <Sidebar /> */}
                <Footer />
              </Router>
            </ErrorBoundaryWrapper>
          </Suspense>
        </div>
      </QueryClientProvider>

      <ToastContainer autoClose={1000} hideProgressBar />
    </div>
  );
};

export default App;
