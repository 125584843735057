import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "tw-elements";
import ProductProvider from "./Itineries/Contexts/ProductContext";
import SidebarProvider from "./Itineries/Contexts/SidebarContext";
import CartProvider from "./Itineries/Contexts/CartContext";
import { Provider } from "react-redux";
import store from "./Modules/Redux/Store";
import { BrowserRouter } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react";
import ReactPixel from "react-facebook-pixel";

ReactPixel.init(process.env.FACEBOOK_PIXEL_ID);
ReactPixel.pageView();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <SidebarProvider>
      <CartProvider>
        <ProductProvider>
          <React.StrictMode>
            <App />
            <Analytics />
          </React.StrictMode>
        </ProductProvider>
      </CartProvider>
    </SidebarProvider>
  </Provider>
);
