import axios from "axios";

const sendEmailFunc = async (sub, bod, toast, setSubmitted) => {
  try {
    if (window.Email) {
      // console.log({
      //   // SecureToken: process.env.REACT_APP_SECURE_TOKEN,
      //   SecureToken: "8e5404b7-f7fc-4a3c-98f2-37107f03cdd5",
      //   To: "imperial.inception@gmail.com",
      //   From: "imperial.inception@gmail.com",
      //   Subject: sub,
      //   Body: bod,
      // });
      window.Email.send({
        // SecureToken: process.env.REACT_APP_SECURE_TOKEN,
        SecureToken: "7a586b7c-5d65-43a1-bae3-df21c718ae75",
        To: "jagannathrkreal@gmail.com",
        From: "imperial.inception@gmail.com",
        // To: "growth@amsantechnology.com",
        // From: "growth@amsantechnology.com",
        Subject: sub,
        Body: bod,
      }).then((message) => {
        if (!toast.isActive("my-toast")) {
          toast.success("Submited. Our team will reach back to you :)");
        }

        setSubmitted(true);

        return true;
      });
    }
  } catch (err) {
    if (!toast.isActive("my-toast")) {
      toast.error("Something went wrong. Try again later :(");
      setSubmitted(true);
      return false;
    }
  }
};

const sendContactData = async (contactData, toast) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_BACKEND_APP_URL + "/send_new_contact",
      contactData
    );
    console.log("Response:", response.data);
    // Handle the response as needed
    if (!toast.isActive("my-toast")) {
      toast.success("Submited. Our team will reach back to you :)");
    }
    return true;
  } catch (error) {
    console.error("Error:", error);
    if (!toast.isActive("my-toast")) {
      toast.error("Something went wrong. Try again later :(");
      return false;
    }
  }
};

export { sendEmailFunc, sendContactData };
