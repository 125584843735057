import React from "react";
import "./Styles/PartnerWithUs.css";
import { sendEmailFunc } from "../Utilities/Functions/SendEmail";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function PartnerWithUs() {
  const [fName, setFName] = React.useState("");
  const [lName, setLName] = React.useState("");
  const [about, setAbout] = React.useState("");

  const [submitted, setSubmitted] = React.useState(false);

  async function sendEmail() {
    if (fName.length == 0 || lName.length == 0 || about.length == 0) {
      toast.error("Please fill all the fields");
      return;
    }

    let res = await sendEmailFunc(
      "New contact from " + fName,
      `                      <p>Hello Team,</p>
                  <p>You got a new contact from ${fName} ${lName}:</p>
                  <p style="padding: 12px; border-left: 4px solid #d0d0d0; font-style: italic;">
                  ${about}
                  </p>
                  <p>
                    Best wishes,<br>Team Figry
                  </p>`,
      toast,
      setSubmitted
    );

    setSubmitted(res);

    setFName("");
    setLName("");
    setAbout("");
  }

  return (
    <div>
      {/* <ToastContainer /> */}
      <div className="sitemenu_partner_with_us">
        <div className="sitemenu_partner_with_us_inner">
          <div className="sitemenu_partner_with_us_inner_div1">
            <h1 className="sitemenu_partner_with_us_inner_div1_h1">
              Partner With Us
            </h1>
            <p className="sitemenu_partner_with_us_inner_div1_p1">
              Join Figry and Elevate the Gift - Giving Experience Together
            </p>
            <p className="sitemenu_partner_with_us_inner_div1_p2">
              At Figry, We believe in the power of meaningful gifts to
              strengthen relationships and create lasting memories. We are
              excited about collaborating with like-minded partners who share
              our passion for exceptional gifting experiences. Whether you are a
              supplier artist or a buisness looking to enhance your offering, We
              welcome you to explore the possibilities of partnering with Figry
            </p>
            <div className="sitemenu_partner_with_us_inner_div1_div1">
              <button className="sitemenu_partner_with_us_inner_div1_div1_button">
                Connect Now
              </button>
            </div>
          </div>
          <div className="sitemenu_partner_with_us_inner_div2">
            <div className="sitemenu_partner_with_us_inner_div2_form1">
              <p className="sitemenu_partner_with_us_inner_div2_form1_p">
                First Name*
              </p>
              <input
                className="sitemenu_partner_with_us_inner_div2_form1_input"
                type="text"
                value={fName}
                onChange={(e) => {
                  setFName(e.target.value);
                }}
              />
            </div>
            <div className="sitemenu_partner_with_us_inner_div2_form1 sitemenu_partner_with_us_inner_div2_form1_margin">
              <p className="sitemenu_partner_with_us_inner_div2_form1_p">
                Last Name*
              </p>
              <input
                className="sitemenu_partner_with_us_inner_div2_form1_input"
                type="text"
                value={lName}
                onChange={(e) => {
                  setLName(e.target.value);
                }}
              />
            </div>
            <div className="sitemenu_partner_with_us_inner_div2_form1 sitemenu_partner_with_us_inner_div2_form1_margin">
              <p className="sitemenu_partner_with_us_inner_div2_form1_p">
                About Your Buisness*
              </p>
              <textarea
                className="sitemenu_partner_with_us_inner_div2_form1_input"
                type="text"
                rows={2}
                value={about}
                onChange={(e) => {
                  setAbout(e.target.value);
                }}
              />
            </div>
            <div className="sitemenu_partner_with_us_inner_div2_form1 sitemenu_partner_with_us_inner_div2_form1_margin">
              <button
                className="sitemenu_partner_with_us_inner_div1_div1_button"
                onClick={() => {
                  sendEmail();
                }}
              >
                {submitted ? "Submitted" : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
