import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

export default function ActiveLastBreadcrumb(props) {
  const navigate = useNavigate();

  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="inherit"
          style={{
            cursor: "pointer",
            fontSize: `${props.size}px`,
            textDecoration: "underline",
            textTransform: "capitalize",
            color: "white",
          }}
          onClick={(e) => {
            navigate("/");
          }}
        >
          Home
        </Link>
      </Breadcrumbs>
    </div>
  );
}
