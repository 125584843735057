import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartItems: [],
    openCart: false,
    trigger: false,
    triggerCheckout: false,
    triggerHamper: false,
    sideBar: false,
    // user: null,
    // firstName: "",
    // lastName: "",
    // phoneNo: "",
    // country: "India",
    // billing_address: "",
    // shipping_address: "",
    // city: "",
    // state: "",
    // postal_code: "",
    // storageName: "figrycart",
  },
  reducers: {
    // setFirstName: (state, action) => {
    //   state.firstName = action.payload;
    // },
    // setLastName: (state, action) => {
    //   state.lastName = action.payload;
    // },
    // setPhoneNumber: (state, action) => {
    //   state.phoneNo = action.payload;
    // },
    // setBillingAddress: (state, action) => {
    //   state.billing_address = action.payload;
    // },
    // setShippingAddress: (state, action) => {
    //   state.shipping_address = action.payload;
    // },
    // setCity: (state, action) => {
    //   state.city = action.payload;
    // },
    // setState: (state, action) => {
    //   state.state = action.payload;
    // },
    // setPostalCode: (state, action) => {
    //   state.postal_code = action.payload;
    // },
    setCartItems: (state, action) => {
      state.cartItems = action.payload;
    },
    setOpenCart: (state, action) => {
      state.openCart = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setTrigger: (state, action) => {
      state.trigger = action.payload;
    },
    setTriggerCheckout: (state, action) => {
      state.triggerCheckout = action.payload;
    },
    setTriggerHamper: (state, action) => {
      state.triggerHamper = action.payload;
    },
    setSideBar: (state, action) => {
      state.sideBar = action.payload;
    },
  },
});

export const {
  setCartItems,
  setOpenCart,
  setUser,
  setTrigger,
  setTriggerCheckout,
  setTriggerHamper,
  setSideBar,
} = cartSlice.actions;

export default cartSlice.reducer;
